/* You can add global styles to this file, and also import other style files */
.owl-prev {
    position: absolute!important;
    top: 35%!important;
    margin-left: -3%!important;
    display: block !important;
    border:0px solid black!important;
    width: 20px!important;
    height: 25px!important;
    background-image: url('assets/img/home/Flecha-anterior.png')!important;
    background-repeat: no-repeat!important;
    background-color: transparent!important;
    background-size: contain!important;
  }
  
  .owl-next {
    position: absolute;
    top: 35%!important;
    right: -3%!important;
    display: block !important;
    border:0px solid black;
    width: 20px!important;
    height: 25px!important;
    background-image: url('assets/img/home/Flecha-siguiente.png')!important;
    background-repeat: no-repeat!important;
    background-color: transparent!important;
    background-size: contain!important;
  }
  
  .owl-prev i, .owl-next i { color: black;}
  .owl-prev , .owl-next  { color: black;}
  .owl-carousel .owl-dots.disabled{
    display: block;
  }

  .owl-dot.active span{
    background: #e62e58!important;
    color: #e62e58!important;
  }

  @font-face {
    font-family: 'Gotham';
    src: url(assets/font/Gotham-Light.otf) format("opentype");
    font-weight: normal;
  }
  @font-face {
    font-family: "Gotham";
    src: url(assets/font/Gotham-Bold.otf) format("opentype");
    font-weight: bold;
  }
  
  body{
   font-family: 'Gotham';
   background: white!important;
   color: black!important;
  }
  /*section:hover{
    animation: shake 1s;
  }*/


  ///////Animation ////////////// 

  /////////////// JELLO /////////////// 
  @keyframes jello {
    11.1% {
      transform: none
    }
    22.2% {
      transform: skewX(-12.5deg) skewY(-12.5deg)
    }
    33.3% {
      transform: skewX(6.25deg) skewY(6.25deg)
    }
    44.4% {
      transform: skewX(-3.125deg) skewY(-3.125deg)
    }
    55.5% {
      transform: skewX(1.5625deg) skewY(1.5625deg)
    }
    66.6% {
      transform: skewX(-0.78125deg) skewY(-0.78125deg)
    }
    77.7% {
      transform: skewX(0.390625deg) skewY(0.390625deg)
    }
    88.8% {
      transform: skewX(-0.1953125deg) skewY(-0.1953125deg)
    }
    100% {
      transform: none
    }
  }

  .jello:hover {
    animation: jello 2s ;
    transform-origin: center;
  }
  
  .jello {
    animation: jello 5s;
    transform-origin: center;
  }

  /////////////////// TADA ///////////////////////////
  @keyframes tada {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
      -ms-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  
    10%, 20% {
      -webkit-transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
      -ms-transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
      transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
    }
  
    30%, 50%, 70%, 90% {
      -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
      -ms-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
      transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    }
  
    40%, 60%, 80% {
      -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
      -ms-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
      transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    }
  
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      -ms-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
  
  .tada {
    animation: tada 5s;
  }

  /////////////////// SHAKE //////////////////////
  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }


  .shake {
    animation: shake 5s;
    transform-origin: center;
  }


  //////////////////// FLASH /////////////// 
  @keyframes flash {
    50%, 100% {
       opacity: 1;
    }
    0%, 25%, 75% {
       opacity: 0;
    }
 }
 
 .flash {
    animation-name: flash 5s;
 }

 //////////// SLIDE //////////////

 @keyframes slideLeft {
  100% { left: 0; }
  }

  .slideLeft {
    animation: slideLeft 5s forwards;
    animation-delay: 2s;
 }

